.checkout {
  .checkout-panel {
    .checkout-buttons-content__container {
      .choose_samples_txt {
        display: none;
      }
    }
    .signin-to-see-cart {
      margin-bottom: 20px;
    }
    .cart-items {
      &__header {
        &--frequency {
          width: 18%;
        }
        &--total {
          width: 13%;
        }
      }
      &__item {
        &--total {
          width: 13%;
        }
        @include breakpoint($medium-portrait-only) {
          &--price,
          &--total {
            width: 30%;
          }
          &--qty {
            clear: left;
          }
        }
      }
    }
  }
  .checkout__sidebar {
    .tab-bar {
      display: block;
      overflow: hidden;
      margin: 20px 0 10px 0;
      border-bottom: 1px solid $color-light-gray;
      li {
        letter-spacing: 0;
        margin: 0;
        width: 50%;
        float: left;
        color: $color-light-gray;
        cursor: pointer;
        font-size: 22px;
        &.active {
          color: $color-dark-gray;
        }
      }
    }
    .tab-content {
      .checkout__panel--header {
        display: none;
      }
      #past-purchases-pane {
        display: none;
        &.active {
          display: block;
        }
        a {
          text-decoration: underline;
        }
        .past-purchases-panel {
          .product-list {
            .product {
              height: auto;
            }
          }
          .details {
            display: table;
          }
        }
      }
      #samples-panel {
        .checkout_complimentary_samples_message {
          margin-left: 2%;
          font-size: 15px;
        }
        .samples-panel__content {
          width: 100%;
          .product-list {
            min-height: 0;
            overflow: hidden;
            li.product {
              width: 100%;
              margin: 0;
              border-bottom: 1px solid $color-light-gray;
              min-height: 0;
              &:last-child {
                border: none;
              }
              .product-img {
                max-width: 140px;
                margin: 0;
                padding: 0;
                float: left;
                width: 40%;
              }
              .details {
                display: table;
                width: 47%;
              }
              .skus {
                li.sku {
                  width: 100%;
                  padding: 0;
                  .sample-select-button {
                    display: inline-block;
                    width: 100%;
                    padding: 12px 6px;
                    color: $white;
                  }
                }
              }
              .view-larger {
                display: none;
              }
            }
          }
          .samples {
            p {
              padding: 0 20px;
            }
          }
        }
      }
    }
    .cart-items__item {
      &--qty {
        float: right;
      }
      &--total {
        width: 25%;
      }
      @include breakpoint($medium-portrait-only) {
        &--qty {
          width: auto;
        }
        &--group-desc-remove {
          width: 30%;
        }
        &--total {
          width: 45%;
        }
      }
    }
    .checkout__empty_panel {
      display: none;
    }
  }
  .checkout__header {
    border-bottom: 1px solid $color-light-gray;
    padding-left: 0;
    h1 {
      font-size: 30px;
      text-transform: uppercase;
    }
  }
  &__content {
    text-align: left;
    #sign-in-panel {
      position: relative;
      overflow: auto;
      padding: 1em 0;
      margin-bottom: 1em;
      .checkout__panel--header {
        padding-bottom: 10px;
        text-transform: uppercase;
      }
      #new-account,
      #return-user {
        width: 50%;
        float: left;
        .checkout__panel--content {
          .form-item {
            input[type='email'],
            input[type='text'],
            input[type='password'] {
              width: 90%;
            }
          }
        }
      }
      #new-account {
        .submit {
          position: absolute;
          bottom: 0;
          margin-bottom: 15px;
          width: 35%;
          input[type='submit'] {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
      #return-user {
        fieldset {
          .submit {
            width: 70%;
            margin-bottom: 0;
            input[type='submit'] {
              width: 100%;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .collapsed {
      display: block;
      padding-bottom: 10px;
      padding-top: 25px;
      border-bottom: 1px solid $color-light-gray;
    }
    .checkout-panel--guarantee {
      padding: 0;
      border-top: 1px solid $color-light-gray;
    }
    #review-panel {
      .checkout__panel--header {
        padding-top: 10px;
      }
      .messages {
        padding: 10px;
      }
      .form-container {
        .radio {
          margin-bottom: 20px;
          input[type='radio'] {
            position: relative;
            margin: 5px 5px 0 0;
            vertical-align: sub;
          }
          .payment_options_image {
            margin: 0 0 0 10px;
          }
        }
      }
      #checkout_review {
        .submit {
          text-align: left;
          input[type='submit'] {
            width: 15%;
          }
        }
      }
    }
  }
  .messages {
    background: none;
    border-bottom: none;
    color: $color-error;
  }
}

#viewcart {
  .checkout {
    #recommended-products-panel {
      .recommended-product-items {
        .recommended-item {
          &__product-info {
            text-align: center;
            &__name,
            &__subname,
            &__subline {
              margin-bottom: 5px;
              &__link {
                float: none;
              }
            }
          }
          &__cta {
            text-align: center;
            margin-top: 20px;
            .button {
              float: none;
            }
          }
        }
      }
    }
  }
}

.samples-overlay {
  #cboxContent {
    padding: 0px;
    overflow: hidden;
    #cboxLoadedContent {
      .samples-panel {
        padding-bottom: 40px;
        header {
          border: none;
          .checkout__panel--heading--container {
            border: none;
            .samples-panel__title {
              font-size: 22px;
              text-transform: uppercase;
            }
          }
        }
        &__content {
          width: 100%;
          ul.product-list {
            max-width: 100%;
            padding: 0 20px;
            .product {
              clear: none;
              height: 360px;
              text-align: center;
              position: relative;
              width: 33%;
              .skus {
                bottom: 0;
                left: 0;
                right: 0;
                position: absolute;
                li.sku {
                  margin-bottom: 0px;
                  .sample-select-button {
                    color: $white;
                  }
                }
              }
              img {
                width: auto;
                margin-bottom: 20px;
              }
              .product-img {
                float: none;
                .view-larger {
                  display: none;
                }
              }
              .details {
                padding: 0 1em;
                .product-brief__subline {
                  font-size: 18px;
                  letter-spacing: normal;
                  margin-bottom: 0;
                }
              }
            }
          }
          .sample_continue {
            text-align: center;
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.checkout-index,
#confirm {
  .site-header {
    &__menu {
      display: none;
    }
    &__utility-left {
      .site-header__utility-item:nth-child(2),
      .site-header__utility-item:nth-child(3) {
        display: none;
      }
      .site-header__utility-item {
        border-right: none;
      }
    }
    &__utility-right {
      display: none;
    }
  }
}

.offer-code-panel {
  .checkout-panel__content {
    .offer-code__form {
      .offer-code-content__input {
        input[type='text'] {
          padding: 0 10px;
          font-size: 15px;
        }
        label {
          font-size: 15px;
        }
      }
    }
    p {
      font-size: 15px;
      margin-top: 15px;
    }
  }
}

#shipping-panel {
  border-top: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;
  margin-right: 10px;
  .checkout-panel--shipping-edit-address {
    .title {
      label {
        width: 100%;
        display: block;
      }
      select {
        width: 49%;
      }
    }
    .address-form__city-state-zip {
      .state {
        float: left;
      }
      .city {
        width: 49%;
        float: right;
        select {
          width: 100%;
        }
      }
    }
    .address-form__phones {
      width: 100%;
      .area-code {
        float: left;
        width: 49%;
        select {
          width: 100%;
        }
      }
      .mobile-number {
        float: right;
        width: 49%;
      }
    }
    .address-options {
      height: 400px;
      &__address-container {
        height: auto;
        word-break: break-word;
      }
    }
  }
  .checkout-panel__heading {
    font-weight: bold;
    margin: 10px 0;
  }
  .edit {
    float: right;
    margin: 20px;
  }
  .checkout__subtitle {
    font-size: 1.5em;
    font-weight: bold;
    margin: 10px 0;
  }
  .shipping-edit-content {
    .address1 {
      select {
        width: 100%;
      }
    }
  }
  .checkout__panel--content {
    padding-left: 0;
  }
  .gift-options {
    .card-message {
      .error {
        border-color: $color-error;
      }
    }
  }
}

#confirm {
  .checkout-confirmation-page {
    #confirmation-panel {
      padding: 25px 0;
      .checkout__panel--content {
        padding: 18px 0;
        .confirmation-panel__actions {
          margin: 0;
        }
      }
    }
    #registration-panel {
      border-top: 1px solid $color-light-gray;
      padding: 20px 0 0 0;
      .checkout__panel--content {
        .form-item {
          width: 50%;
          input[type='password'],
          input[type='submit'] {
            width: 100%;
          }
        }
        .checkbox {
          width: 100%;
        }
      }
      h2,
      h3 {
        font-size: 20px;
        text-transform: uppercase;
      }
    }
    #sign-in-confirmation-panel {
      border-top: 1px solid $color-light-gray;
      padding: 20px 0;
      .messages {
        padding: 0;
      }
      .checkout__panel--header {
        h2 {
          font-size: 20px;
          text-transform: uppercase;
        }
      }
      .form-item {
        padding-bottom: 5px;
      }
    }
    .checkout-panel--guarantee {
      .checkout-panel__header {
        padding-top: 10px;
      }
    }
  }
}

.zh_HK {
  .account-section {
    .order-details-page__content {
      .cart-item__products {
        .cart-item__product-sub_line {
          display: block;
        }
      }
    }
  }
}

.site-footer {
  .site-footer__bottom-condensed {
    .site-footer__bottom-checkout {
      .divider {
        display: none;
      }
    }
  }
}

@include breakpoint($medium-portrait-only) {
  .checkout-samples {
    .samples-page {
      .checkout__panel {
        width: 650px;
        margin: auto;
        .checkout__panel--header {
          .checkout__panel--heading--container {
            border-bottom: 1px solid $color-light-gray;
            margin-top: 15px;
            .checkout__heading {
              margin-bottom: 10px;
            }
          }
          .remaining {
            .samples_header {
              border-bottom: 1px solid $color-light-gray;
              padding: 10px 0;
              text-transform: uppercase;
            }
          }
        }
        .continue-shopping-outer-container {
          text-align: right;
          margin-bottom: 10px;
        }
        &--content {
          .samples {
            .product-list {
              .details {
                .sample-select-button {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  .checkout-index {
    .checkout {
      &__content {
        #shipping-panel {
          .gift-options {
            .card-message {
              textarea {
                width: 85%;
              }
            }
          }
        }
      }
      &-progress-bar {
        display: none;
      }
    }
  }
}

.checkout-index {
  #error_offer_bogo {
    display: none;
  }
}

.samples-page {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  max-width: 1280px;
  z-index: 1;
  .checkout__panel--header {
    text-align: center;
  }
  .continue-shopping-outer-container {
    &.bottom {
      clear: both;
      margin-bottom: 16px;
    }
    .continue-shopping-button {
      float: right;
      &-link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .offer {
    clear: both;
  }
  .product-list {
    .product {
      height: 492px;
    }
    .sku {
      &.button {
        position: absolute;
        max-width: 140px;
        left: 0;
        bottom: 0;
        right: 0;
        margin: -15px auto;
        .sample-select-button {
          color: $color-white;
        }
      }
    }
  }
}

.checkout-samples_landing {
  .checkout__header {
    border-bottom: 0;
  }
  .sample-select-button {
    color: $color-white;
    position: absolute;
    max-width: 140px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 10px auto;
  }
}

#registration-panel {
  fieldset {
    .mobile-phone {
      select,
      input[type='text'] {
        width: 50%;
        height: 40px;
      }
      .mobile-code,
      .mobile-number_field {
        width: 49%;
        display: inline-block;
      }
    }
    .form-item {
      &.sister_brand_opt-out {
        text-align: left;
      }
    }
  }
}
