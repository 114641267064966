/* Disconnect Button in Profile Preference Page */
#facebook_disconnect input.form-submit {
  margin: 0;
  float: none;
}
/* For displaying FB Button */
.social-login-section {
  .social-login {
    margin: 0;
    .facebook-button {
      display: none;
    }
    &__container {
      display: block;
      position: static;
    }
    &__email-opt-in {
      text-align: left;
      padding-bottom: 15px;
    }
    &__divider {
      margin: 0;
    }
  }
}
/* To align the Register button for New User and social login terms */
.checkout__content {
  #sign-in-panel {
    #new-account {
      .submit {
        bottom: auto;
      }
    }
    .social-login__divider {
      margin-top: 30px;
    }
  }
  .social-login-section {
    .social-login__terms p {
      text-align: left;
    }
  }
}
/* Social login Divider in account Signin Page */
#signin fieldset {
  .return-user__divider,
  .new-account__divider {
    @include breakpoint($medium-up) {
      display: inline-block;
    }
  }
  .new-account__container {
    .social-login__divider {
      &:before {
        @include breakpoint($large-up) {
          height: 338px;
        }
      }
    }
  }
}
/* Order Confirmation Page */
#confirmation-panel {
  .social-login-section {
    border-top: 1px solid $color-light-gray;
    margin: 0 -15px;
    padding: 0 15px;
    @include breakpoint($medium-up) {
      margin: 0;
      padding: 0;
    }
    .social-login {
      &__divider {
        display: none;
      }
      &__title {
        display: block;
        text-align: left;
      }
      &__info {
        display: block;
        text-align: left;
        width: auto;
      }
      &__container {
        margin: 0;
      }
    }
  }
}
/* Account Signin Page */
.sign-in-page {
  .new-account__fieldset {
    .new-account__social {
      .social-login-section {
        @include breakpoint($medium-up) {
          margin-top: 18%;
        }
      }
    }
  }
}
/* GNAV Style */
.social-login.gnav {
  .social-login {
    &__terms label {
      display: none;
    }
  }
}

.site-header__utility {
  .gnav-util__content--account {
    .social-login {
      &__container {
        position: unset;
        .social-login__email-opt-in label {
          float: left;
        }
      }
      &__terms {
        text-align: justify;
        padding: 0;
      }
    }
  }
}

.profile-page__content {
  .social-info {
    padding: 0 20px 20px;
    @include breakpoint($medium-up) {
      padding: 0;
    }
  }
}
/* Gnav */
.gnav-util--account {
  .social-login {
    &__terms {
      display: inline-flex;
      margin-top: 15px;
    }
  }
}
/* Account Page Ipad */
.new-account__fieldset,
.return-user__fieldset {
  .new-account__divider,
  .return-user__divider {
    h2 {
      @include breakpoint($tablet-only) {
        display: none;
      }
    }
  }
  .new-account__social {
    .social-login-section {
      @include breakpoint($tablet-only) {
        margin-top: 0;
      }
    }
  }
}
