body {
  .site-header {
    .language-select {
      &__item--01 {
        display: inline-block;
        padding: 0 10px;
        border-right: 1px solid $color-lightest-grey;
      }
      &__item--02 {
        display: inline-block;
        padding-left: 10px;
      }
      &__link--selected {
        color: $color-green;
        font-weight: bold;
      }
    }
    &__utility-left {
      li.site-header__utility-item:nth-child(2) {
        border-right: none;
      }
    }
    .gnav-util__content {
      height: auto;
      .sign-in {
        .registration__container {
          .registration__name--first,
          .registration__name--last {
            display: none;
          }
        }
        .error_messages {
          padding-right: 15px;
        }
      }
      .cart-item__title {
        display: none;
      }
    }
    &__trustmark-wrapper {
      float: left;
      position: relative;
      top: -18px;
      @include breakpoint($medium-portrait-only) {
        top: -7px;
        left: 70px;
      }
    }
    &__menu-wrapper {
      clear: both;
    }
    &__utility {
      @include breakpoint($medium-portrait-only) {
        ul.site-header__utility-left {
          display: block;
          margin-left: 5em;
          li.site-header__utility-item:nth-child(1) {
            display: block;
            float: left;
          }
        }
        li.site-header__utility-item:nth-child(2),
        li.site-header__utility-item:nth-child(3),
        .language-select {
          display: none;
        }
        .language-select {
          margin-top: 2px;
        }
        div.site-header__utility-left {
          position: absolute;
        }
      }
      @include breakpoint($landscape-up) {
        &-logo {
          width: min-content;
        }
      }
    }
  }
  .footer {
    .site-footer-pc {
      .site-footer__country-chooser-wrapper {
        a.selectBox-dropdown {
          position: absolute;
        }
      }
      @include breakpoint($medium-portrait-only) {
        .site-footer__country-chooser-wrapper {
          bottom: -35px;
          select {
            line-height: 35px;
            height: 35px;
          }
        }
      }
      @include breakpoint($medium-landscape-only) {
        .site-footer__country-chooser-wrapper {
          bottom: -15px;
        }
      }
      &__column--1 {
        .menu {
          li:nth-child(1) {
            display: none;
          }
        }
      }
      &__column--3 {
        .content {
          .social-media-links {
            margin-top: 95px;
            margin-bottom: 0;
          }
        }
      }
      @include breakpoint($medium-portrait-only) {
        &__column--1 {
          .menu {
            li:nth-child(1) {
              display: block;
            }
          }
        }
        &__column--3 {
          margin-top: 10px;
        }
      }
    }
    .site-footer__bottom {
      &-links {
        padding-right: 12%;
      }
    }
    .language-select {
      display: none;
      @include breakpoint($medium-portrait-only) {
        display: block;
        text-align: center;
        &__item {
          margin-top: 10px;
        }
        &__item--01,
        &__item--02 {
          display: inline-block;
          padding: 0 10px 0 5px;
          border-right: 1px solid $color-lightest-grey;
        }
        &__item--02 {
          border-right: none;
        }
        &__link--selected {
          color: $color-sand;
        }
      }
    }
  }
  #footer_sticky_chat {
    position: fixed;
    left: 10px;
    bottom: 10px;
    z-index: 9998;
  }
  /* Loyalty changes */
  .sign-in-page {
    .new-account {
      .form-item {
        &.join-loyalty {
          text-align: #{$ldirection};
        }
      }
    }
  }
  .loyalty__market__status {
    @include breakpoint($medium-portrait-only) {
      padding-top: 10px;
    }
    &__content {
      .loyalty__market {
        &__status__section {
          vertical-align: middle;
          padding: 10px;
          height: auto;
          width: 22%;
          text-align: #{$ldirection};
          &__tier-2 {
            @include breakpoint($medium-portrait-only) {
              width: 26%;
            }
          }
        }
      }
    }
    &__header {
      .loyalty__market__status {
        &__section {
          width: 22%;
        }
        &__header__tier-2 {
          @include breakpoint($medium-portrait-only) {
            width: 26%;
          }
        }
      }
    }
    &__section {
      .loyalty__market__status {
        &__header__title {
          @include breakpoint($medium-portrait-only) {
            font-size: 20px;
          }
        }
      }
    }
  }
  .loyalty__market__header {
    @include breakpoint($medium-portrait-only) {
      background-image: url(/media/export/cms/loyalty/loyalty_marketing_pc_banner.png);
    }
  }
  .loyalty__panel__offers {
    &__sku__list-container {
      .slides {
        li {
          margin-#{$rdirection}: 10px;
          @include breakpoint($medium-portrait-only) {
            width: 32%;
            text-align: center;
            display: inline-block;
            padding-bottom: 20px;
            &:nth-child(3n) {
              margin-#{$rdirection}: 0;
            }
          }
          .loyalty__panel__offers__sku {
            &__product-image-container {
              height: auto;
            }
            &__product__terms {
              min-height: 70px;
            }
          }
        }
      }
      .loyalty__panel {
        &__offers__sku__cta {
          margin-top: 10px;
        }
      }
    }
    &__offer-data-container {
      margin-bottom: 15px;
      .loyalty__panel__offers {
        &__promo_desc {
          min-height: 100px;
        }
        &__promo_title {
          min-height: 85px;
        }
      }
    }
    .loyalty__panel__subtitle {
      display: block;
    }
    &__promo_title {
      @include breakpoint($medium-portrait-only) {
        height: 60px;
      }
    }
  }
  .pr-war {
    .pr-loyaltymember2-form-group,
    .pr-wavesdelmmember2-form-group {
      display: none;
    }
  }
  .write_a_review {
    &__container {
      .thank-you-page {
        #loyaltymember2,
        #wavesdelmmember2 {
          display: none;
        }
      }
    }
  }
  .checkout__content {
    .checkout-panel {
      &__content__banner {
        margin-bottom: 10px;
      }
      &__content__logo {
        margin-bottom: 10px;
        padding-#{$rdirection}: 15px;
        @include breakpoint($landscape-up) {
          float: #{$ldirection};
          width: 48%;
        }
        .mobile-hidden {
          width: 100%;
        }
      }
      &--heading {
        margin-top: 10px;
      }
      &__content__points {
        padding-#{$ldirection}: 5px;
        @include breakpoint($landscape-up) {
          width: 49%;
          float: #{$ldirection};
        }
      }
      &__content__title {
        line-height: 2.5;
      }
      &__content__url {
        background: $color-dark-grey;
        color: $color-white;
        width: 95%;
        display: block;
        text-align: center;
        height: 45px;
        line-height: 2.8;
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
          background: $color-green;
          color: $color-white;
          border-color: $color-green;
        }
      }
      .registration-content {
        &__heading {
          text-transform: none !important;
          font-size: 15px !important;
        }
      }
    }
  }
  .checkout {
    .guest-loyalty-message {
      color: $color-red;
    }
  }
  .offer-banner-item {
    display: inline;
  }
}

.welcome15-overlay #cboxLoadedContent {
  .welcome-offer-popup-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__button {
      width: 200px;
      border: none;
      border-radius: 0;
      margin: 20px 10px;
      padding: 10px;
      &:hover {
        color: $color-dark-gray;
      }
    }
  }
}
