.appt-book {
  .appointment-select {
    .appointments-container {
      .book-appt-container {
        fieldset {
          &.mobile-phone-fieldset {
            .appt-book-mobile {
              width: 100%;
            }
          }
        }
        .registration__privacy-policy-list {
          font-family: $font--neue-haas-unica-pro;
          font-size: 12px;
          font-variant: normal;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 18px;
          width: 48.2%;
          float: left;
          margin: 4px 0px 0px 28px;
        }
        .appt_hk_phone_code {
          height: 45px;
          border: 1px solid $color-gray-light;
        }
        .appt-book-hk-mobile-fe {
          margin: 0px;
          width: 39%;
        }
        .registration__email-list-text {
          &.error {
            a {
              color: $color-error;
            }
          }
        }
      }
      .error-messages {
        display: block;
      }
    }
  }
  .service-select {
    .service {
      min-height: 306px;
      &_tile {
        .service-image {
          max-width: 231px;
        }
        .service_tile-content {
          width: 55%;
          @include breakpoint($medium-portrait-only) {
            width: 49.5%;
            float: right;
          }
        }
      }
    }
  }
}

#appointments .appt-book {
  .my-appointments {
    width: 100%;
    position: relative;
    .appt-book-content-header {
      margin: 0;
      h2 {
        display: none;
      }
      h4 {
        font-family: $font--lamer-text;
        font-size: 10px;
        color: $color-gray-medium-dark;
        letter-spacing: 2.5px;
        line-height: 10px;
        text-transform: uppercase;
        font-weight: bold;
      }
      p {
        width: 56%;
        margin: 18px auto;
      }
    }
    #my-appointments-current .appointment-details {
      .your-lessons {
        span.lesson {
          font-family: $font--lamer-headline-black;
          font-size: 32px;
          color: $black;
          letter-spacing: 0px;
          line-height: 36px;
          padding-top: 20px;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      .appointment-info-content {
        width: 100%;
        max-width: 1220px;
        margin: 30px auto 35px;
        border-top: 1px solid $color-gray-light;
        border-bottom: 1px solid $color-gray-light;
        font-weight: bold;
        padding: 0;
        height: 142px;
        .appointment-info-detail {
          display: inline-table;
          border-right: 1px solid $color-gray-light;
          height: 141px;
          padding: 0px 4px;
          vertical-align: top;
          float: left;
          &:nth-last-child(1) {
            border-right: none;
          }
          h4 {
            font-family: $font--lamer-text;
            font-size: 10px;
            color: $color-gray-medium-dark;
            letter-spacing: 2.5px;
            line-height: 10px;
            text-transform: uppercase;
            padding-top: 35px;
          }
          span {
            font-family: $font--lamer-headline-black;
            font-size: 24px;
            color: $black;
            letter-spacing: 0px;
            line-height: 36px;
            padding-top: 27px;
          }
          span.appt-total-time {
            text-transform: none;
          }
          span.appt-date {
            line-height: 36px;
          }
        }
        .date-time {
          width: 39%;
          h4 {
            &:nth-child(2) {
              padding-top: 0px;
            }
          }
        }
        .start-time {
          width: 20%;
        }
        .total-time {
          width: 20%;
          border-right: none;
        }
        .total-cost {
          width: 20%;
        }
        &.total-cost-added {
          .total-time {
            border-right: 1px solid $color-gray-light;
          }
        }
      }
      .location {
        h4 {
          font-family: $font--lamer-text;
          font-size: 10px;
          color: $color-gray-medium-dark;
          letter-spacing: 2.5px;
          line-height: 10px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 28px;
        }
        span {
          line-height: 24px;
        }
        span.location-name {
          font-family: $font--lamer-headline-black;
          font-size: 24px;
          color: $black;
          letter-spacing: 0px;
          line-height: 28px;
          font-weight: bold;
          text-transform: uppercase;
          padding-top: 22px;
          padding-bottom: 10px;
          display: inline;
        }
        .location-direction-name {
          margin-top: 32px;
          span {
            font-size: 14px;
            font-family: $font--lamer-headline;
            line-height: 4px;
          }
        }
        .location-link {
          width: 100%;
          max-width: 1220px;
          border-top: 1px solid $color-gray-light;
          border-bottom: 1px solid $color-gray-light;
          margin: 35px auto 20px;
          height: 76px;
          line-height: 76px;
        }
        a.location-direction-links {
          font-family: $font--arial-times-lino;
          background: transparent url(/media/export/cms/appointment_booking/common/get_directions_arrow_right.png)
            no-repeat left center;
          padding-left: 20px;
          text-indent: 40px;
          font-size: 12px;
          color: $black;
          letter-spacing: 0px;
          border: 0;
          &::before {
            background: transparent url(/media/export/cms/appointment_booking/common/get_directions_arrow_right.png)
              no-repeat;
          }
        }
      }
    }
    .appointments-container {
      position: relative;
      width: auto;
      overflow: hidden;
      margin: 0 3.6% 0;
      .mobile-header {
        display: none;
      }
    }
    .appointments-container.past {
      height: auto;
      overflow: hidden;
      border-bottom: 0;
      .past-appt-head {
        width: 100%;
        position: relative;
        text-align: center;
        border-top: 2px solid $black;
        max-width: 1200px;
        border-bottom: 1px solid $color-gray-light;
        a.view-link {
          font-family: $font--arial;
          font-weight: bold;
          font-size: 16px;
          text-transform: none;
          color: $black;
          text-align: center;
          display: block;
          height: 84px;
          padding: 28px 0 0;
          letter-spacing: 0px;
          line-height: 26px;
          position: relative;
          text-decoration: none;
          border: none;
        }
        .section-header {
          border-bottom: 0 none;
          font-size: 16px;
          letter-spacing: 0.1em;
          margin: 20px 0;
          text-align: center;
          .inner {
            padding: 0 14px 0 18px;
          }
          &__inner {
            padding: 0 14px 0 18px;
          }
        }
      }
      .appointment-details {
        margin: 0;
        border-bottom: 1px solid $color-gray-light;
        padding: 30px 10% 0px;
        height: 435px;
        .location {
          border-top: 1px solid $color-gray-light;
          margin-top: 25px;
          padding: 32px 0 0;
        }
        .location-name {
          font-family: $font--arial-times-lino;
          font-size: 15px;
          color: $black;
          letter-spacing: 0px;
          line-height: 36px;
          padding-top: 0px;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 15px;
        }
        .location-address {
          line-height: 20px;
        }
        .location-address2 {
          line-height: 20px;
        }
        .location-town-postcode {
          line-height: 20px;
        }
        .location-phone {
          line-height: 20px;
        }
        h4 {
          font-family: $font--arial;
          font-size: 10px;
          color: $color-gray-medium-dark;
          letter-spacing: 2.5px;
          line-height: 10px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 17px;
        }
        .your-lessons {
          span.lesson {
            font-family: $font--arial-times-lino;
            font-size: 15px;
            color: $black;
            letter-spacing: 0px;
            line-height: 36px;
            padding-top: 0px;
            text-transform: uppercase;
            font-weight: bold;
          }
          .total-time {
            span {
              display: inline-block;
              font-family: $font--arial;
              font-size: 15px;
            }
            span.appt-total-time {
              background: url(/media/export/cms/appointment_booking/common/clock_icon_total.png) 0 18px no-repeat;
              text-indent: 20px;
              display: inline-block;
              padding: 17px 0 0;
              width: auto;
            }
          }
        }
      }
    }
    .appointments-container.past.expanded {
      .past-appt-head {
        a.view-link {
          border-bottom: 1px solid transparent;
          background: transparent url('/media/export/cms/appointment_booking/common/past_appt_arrow.gif') no-repeat
            scroll 0 52px;
        }
      }
    }
    .appointment-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-bottom: 0;
      .appointment-details {
        font-size: 18px;
        line-height: 22px;
        margin: 0px auto 20px;
        overflow: hidden;
        position: relative;
        width: 100%;
        > div {
          padding: 2px 10px 0;
          h4 {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 18px;
            padding-bottom: 0;
            margin: 1px 5px 0;
            text-align: center;
            text-transform: none;
          }
          span {
            display: block;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
          }
        }
        .location {
          a.location-direction {
            display: none;
          }
          a {
            border-bottom: 1px solid $black;
            padding-bottom: 0;
            font-size: 14px;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 0.23em;
          }
        }
      }
    }
    #my-appointments-past {
      .appointment-container {
        width: 50%;
        float: left;
        border-left: 1px solid $color-gray-light;
        border-right: 1px solid $color-gray-light;
        padding: 0;
        &:nth-child(2n + 1) {
          border-left: 0;
        }
      }
    }
    .appt-booking-links {
      margin: 25px 0;
      h4 {
        font-size: 18px;
        margin: 0 0 20px;
        letter-spacing: 0.1em;
      }
      a.button {
        background-color: $black;
        float: none;
        font-size: 13px;
        letter-spacing: 0.1em;
        line-height: 21px;
        height: 43px;
        padding: 11px 45px;
        &:hover {
          border-color: $color-gray-mdark;
          cursor: pointer;
          background: $color-gray-mdark none repeat scroll 0 0;
          color: $white;
          text-decoration: none;
        }
      }
      .viewcart-buttons {
        a.go-shopping {
          background-color: $color-light-red;
        }
      }
      a.recommended-item__button {
        background-color: $color-light-red;
      }
    }
    .appointments-buttons {
      margin-bottom: 42px;
      .text {
        margin-bottom: 35px;
        span {
          display: block;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
        }
      }
      a.cancel-appt-btn {
        font-size: 13px;
        letter-spacing: 0.1em;
        line-height: 21px;
        height: 43px;
        padding: 11px 45px;
        margin-left: 23px;
        border-bottom: none;
        &:hover {
          background: $color-gray-mdark none repeat scroll 0 0;
          border-color: $color-gray-mdark;
          color: $white;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .change-appt-btn {
        font-size: 13px;
        letter-spacing: 0.1em;
        line-height: 21px;
        height: 43px;
        padding: 11px 45px;
        margin-right: 23px;
        &:hover {
          background: $color-gray-mdark none repeat scroll 0 0;
          border-color: $color-gray-mdark;
          color: $white;
          cursor: pointer;
          text-decoration: none;
        }
      }
      .change-appt-btn.mobile {
        display: none;
      }
      a.location-direction-links {
        border-color: $black;
        font-size: 11.66px;
        text-transform: uppercase;
      }
    }
    .appointments-buttons.top {
      display: none;
    }
    .redeemable-appointment {
      border-top: 1px solid $color-gray-light;
      width: 100%;
      max-width: 1220px;
      margin: 0 auto;
      margin-top: 50px;
      padding-top: 42px;
      display: none;
      span.redeemable-appointment {
        font-family: $font--arial;
        font-size: 12px;
        color: $black;
        letter-spacing: 0px;
        line-height: 18px;
        border-top: none;
        padding-top: 0;
        margin-top: 0;
      }
    }
  }
  .appt-book-sections-container {
    margin: 0 auto;
    max-width: 951px;
    width: 100%;
    #appt-book-my-appointments-no-appts {
      .no-appts-content {
        font-family: $font--arial;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
      }
      .button-container {
        display: inline-block;
        margin: 20px 0;
      }
    }
  }
  #appt-book-my-appointments-no-appts {
    #appt-book-btn {
      font-size: 13px;
      letter-spacing: 0.1em;
      line-height: 21px;
      height: 43px;
      padding: 11px 45px;
    }
  }
  .appointment-cancellation {
    width: 100%;
    position: relative;
    .cancel-content {
      display: none;
    }
    .error-content {
      display: none;
    }
    .button-container {
      display: block;
      margin-top: 40px;
      text-align: center;
    }
  }
  .body-container {
    .pg_wrapper {
      width: auto;
    }
    #appointment-book-container {
      &.appt-book {
        margin: 0 auto 25px;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-align: center;
      }
    }
  }
  .appt-book-page-header {
    color: $black;
    display: block;
    margin: 0 auto 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    padding-bottom: 10%;
    .appt-book-page-header-bg {
      display: none;
      height: auto;
      width: 100%;
      &.bg-img-mobile {
        display: none;
      }
    }
  }
  .appt-book-page-header-content {
    width: 100%;
    h2 {
      font-family: $font--arial-times-lino;
      font-size: 56px;
      letter-spacing: -0.3px;
      line-height: 54px;
      padding-bottom: 0;
    }
    h4 {
      font-family: $font--arial;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin-top: 18px;
      text-transform: none;
    }
    .inner_mob {
      display: none;
    }
    .section-header {
      margin-top: 3px;
      margin-bottom: 3px;
      &__inner {
        font-size: 23px;
      }
    }
  }
  .viewcart-buttons {
    .appt-book {
      .my-appointments {
        .appt-booking-links {
          a.go-shopping {
            background-color: $color-light-red;
          }
        }
      }
    }
  }
  .appt-book-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $white;
    z-index: 100;
    .overlay-content-container {
      position: absolute;
      display: block;
      text-align: center;
      background-color: $white;
      z-index: 1;
      width: 428px;
      height: auto;
      top: 50%;
      left: 50%;
      margin-top: -95px;
      margin-left: -214px;
      .overlay_container {
        border: 1px solid $color-gray-light;
        height: auto;
        margin: 10px;
        #confirm-cancel {
          padding: 20px 20px 30px;
        }
        #appt-canceled {
          padding: 15px;
        }
      }
      .overlay-close {
        background: transparent url('/media/export/cms/appointment_booking/common/overlay_close_btn.png') no-repeat;
        cursor: pointer;
        display: block;
        height: 16px;
        overflow: hidden;
        position: absolute;
        right: 4.5%;
        text-indent: 100%;
        top: 9%;
        white-space: nowrap;
        width: 16px;
        z-index: 3;
      }
      .overlay-content {
        display: block;
        position: relative;
        text-align: center;
        p {
          line-height: 1.75;
          margin-top: 19px;
        }
      }
    }
    &.appt-book-services-overlay,
    &.appt-book-book-conflict-overlay {
      .overlay-content-container {
        width: 428px;
        height: 185px;
        top: 50%;
        left: 50%;
        margin-top: -95px;
        margin-left: -214px;
      }
    }
    &.appt-book-datepicker-overlay {
      z-index: 1000;
      .overlay-content-container {
        width: 950px;
        height: auto;
        top: 100px;
        left: 50%;
        margin-left: -475px;
        .pika-single {
          color: $black;
          border: none;
        }
        .pika-title {
          padding: 0 10px;
          button {
            &:hover {
              background-color: transparent;
            }
          }
          .offers-vip__button {
            &:hover {
              background-color: transparent;
            }
          }
        }
        .pika-table {
          abbr[title] {
            border: none;
            text-decoration: none;
            cursor: default;
          }
          abbr {
            border: none;
            text-decoration: none;
            cursor: default;
          }
        }
        .pika-button {
          &:hover {
            background-color: $black;
            color: $white;
            border-radius: 0;
          }
        }
        .pika-prev {
          background: transparent url('/media/export/cms/appointment_booking/appointments/cal_arrow_left.png') no-repeat;
        }
        .is-rtl {
          .pika-next {
            background: transparent url('/media/export/cms/appointment_booking/appointments/cal_arrow_left.png')
              no-repeat;
          }
          .pika-prev {
            background: transparent url('/media/export/cms/appointment_booking/appointments/cal_arrow_right.png')
              no-repeat;
          }
        }
        .pika-next {
          background: transparent url('/media/export/cms/appointment_booking/appointments/cal_arrow_right.png')
            no-repeat;
        }
      }
    }
  }
  .appt-book-overlay.my-appointments-overlay {
    .overlay-content-container {
      width: 431px;
      min-height: 212px;
      top: 50%;
      left: 50%;
      margin-top: -95px;
      margin-left: -214px;
      .canceled {
        h4 {
          font-family: $font--arial-times-lino;
          font-weight: bold;
          font-size: 21px;
          color: $black;
          letter-spacing: 0px;
          line-height: 21px;
          text-transform: uppercase;
          margin: 15px auto 35px;
          width: 100%;
          padding-bottom: 25px;
          border-bottom: 1px solid $color-gray-light;
        }
        .your-lessons {
          span.lesson {
            font-family: $font--arial-times-lino;
            font-size: 18px;
            color: $black;
            letter-spacing: 0px;
            line-height: 26px;
            text-transform: uppercase;
            display: block;
          }
        }
        p {
          font-family: $font--arial;
          font-size: 14px;
          color: $black;
          letter-spacing: 0px;
          line-height: 21px;
          margin: 17px 0 40px;
          span {
            display: block;
            text-align: center;
            &:nth-child(1) {
              font-weight: bold;
            }
          }
        }
        span.location-name {
          font-weight: bold;
        }
        .book-new-btn {
          font-family: $font--arial-times;
          font-size: 13px;
          background-color: $black;
          letter-spacing: 0px;
          line-height: 0;
          margin-bottom: 7px;
          padding: 22px 28px;
          &:hover {
            background: $color-gray-mdark none repeat scroll 0 0;
            border-color: $color-gray-mdark;
            color: $white;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
      #confirm-cancel {
        p {
          font-family: $font--arial-times-lino;
          font-size: 21px;
          color: $black;
          font-weight: bold;
          letter-spacing: 0px;
          line-height: 21px;
          padding-bottom: 35px;
          margin-top: 10px;
          margin-bottom: 40px;
          text-transform: uppercase;
          border-bottom: 1px solid $color-gray-light;
        }
        .yes-btn {
          font-family: $font--arial-times;
          font-size: 13px;
          color: $white;
          letter-spacing: 0px;
          display: inline-block;
          background-color: $black;
          line-height: 45px;
          height: 45px;
          width: 100px;
          margin-right: 14px;
          &:hover {
            cursor: pointer;
            background-color: $color-gray-mdark;
          }
        }
        .no-btn {
          font-family: $font--arial-times;
          font-size: 13px;
          color: $white;
          letter-spacing: 0px;
          display: inline-block;
          background-color: $black;
          line-height: 45px;
          height: 45px;
          width: 100px;
          margin-left: 14px;
          &:hover {
            cursor: pointer;
            background-color: $color-gray-mdark;
          }
        }
      }
      &.appointment-canceled {
        width: 588px;
        left: 44%;
        top: 34%;
      }
    }
  }
  .appt-book-content-header {
    .section-header {
      margin-top: 3px;
      margin-bottom: 3px;
    }
    &__inner {
      font-size: 23px;
    }
  }
  .no-rgba {
    .appt-book-overlay {
      background: transparent url(/media/export/cms/appointment_booking/common/overlay_tint.png) repeat;
    }
  }
}

#appointments {
  #appt-book-my-appointments-overlay {
    #confirm-cancel {
      height: auto;
      border: none;
      .yes-btn {
        display: inline;
      }
      .no-btn {
        display: inline;
      }
      p {
        font-size: 16px;
        letter-spacing: 0;
      }
    }
    #appt-canceled {
      height: auto;
      padding: 0;
      border: none;
      h4 {
        font-family: $font--neue-haas-unica-pro;
        letter-spacing: 0;
      }
      .your-lessons {
        .lesson {
          font-family: $font--neue-haas-unica-pro;
          font-size: 16px;
        }
      }
      p {
        font-family: $font--neue-haas-unica-pro;
        font-size: 14px;
      }
    }
    .appointment-canceled {
      min-height: 228px;
    }
  }
  .appointments-container {
    .appointment-select {
      .calendar-head {
        .cal-head-content {
          .cal-copy {
            span {
              text-transform: none;
            }
          }
        }
      }
    }
  }
}

#confirmation {
  .confirmation-container {
    .appointment-info-content {
      .appointment-info-detail {
        .appt-date {
          text-transform: none;
        }
      }
    }
  }
}

#appointment-book-container {
  .appt-book-page-header {
    .appt-book-page-header-content {
      &__title {
        font-family: $font--lamer-headline-black;
      }
      &__subhead {
        font-family: $font--sans;
      }
    }
  }
  #appointment-book-sections {
    .confirmation-container {
      .your-lessons {
        h4 {
          font-family: $font--lamer-text;
        }
        .lesson {
          font-family: $font--lamer-headline-black;
        }
      }
      .appointment-info-content {
        h4 {
          font-family: $font--lamer-text;
        }
        span {
          font-family: $font--lamer-headline-black;
        }
      }
      .location {
        h4 {
          font-family: $font--lamer-text;
        }
      }
    }
  }
}

.appt-book-services-overlay,
.appt-book-book-conflict-overlay,
.appt-book-location-conflict-overlay {
  &.appt-book-overlay {
    .overlay-content-container {
      .overlay-content {
        p {
          border-top: 0;
          font-size: 16px;
          margin-top: 24px;
        }
      }
    }
  }
}

.appt-book-location-selector {
  .form-container {
    .location-select {
      margin-left: 35px;
    }
  }
  input[name='virtual_oab_cta'] {
    display: block;
    @include swap_direction(margin, 0 auto);
  }
}

[lang='zh-hant-e-HK'] {
  #appointment-book-container {
    &.counters {
      .appt-book-page-header-content {
        padding: 50px 0px 50px 80px;
      }
    }
  }
}
