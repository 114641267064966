.change-password {
  .password-field {
    position: relative;
    &__info {
      @include breakpoint($medium-up) {
        position: absolute;
        #{$ldirection}: 100%;
        top: 31%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 53%;
        border: 1px solid $color-gray;
        margin-#{$rdirection}: 3%;
      }
      @include breakpoint($medium-portrait-only) {
        #{$rdirection}: 100%;
        width: 54%;
        margin-#{$rdirection}: 2%;
      }
      @include breakpoint($ipad-pro-portrait-only) {
        width: 37%;
      }
      &__item {
        margin-#{$rdirection}: 15px;
        input {
          width: 100%;
        }
      }
      &--change_password_link {
        margin-top: 8px;
      }
      &-reset {
        bottom: auto;
        top: 50%;
        @include breakpoint($medium-up) {
          width: 170px;
        }
        @include breakpoint($medium-portrait-only) {
          #{$ldirection}: 32%;
        }
        @include breakpoint($ipad-pro-portrait-only) {
          #{$ldirection}: 49%;
        }
      }
      &-confirm {
        bottom: auto;
        top: 20%;
        #{$ldirection}: 100%;
        margin-#{$ldirection}: 2%;
      }
      &-gnav {
        position: relative;
        #{$ldirection}: 1px;
        top: 30px;
        width: 100%;
        border: 1px;
      }
      &-checkout {
        margin-#{$ldirection}: 8%;
        display: flex;
        @include breakpoint($medium-portrait-only) {
          width: 42%;
          #{$rdirection}: 79%;
        }
        @include breakpoint($medium-up) {
          width: 26%;
          #{$ldirection}: 22%;
        }
        #{$ldirection}: 25%;
        width: 26%;
      }
      width: 100%;
      background-color: $color-white;
      z-index: 99;
      margin-#{$ldirection}: 7px;
      &:before {
        @include breakpoint($medium-up) {
          content: '';
          position: absolute;
          top: 43%;
          #{$rdirection}: 100%;
          border: 5px solid transparent;
          border-#{$rdirection}-color: $color-gray;
          -ms-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
          transform: rotate(360deg);
          #{$ldirection}: -10px;
        }
      }
      &-reset,
      &-confirm {
        &:before {
          #{$ldirection}: 1px;
          top: 45%;
          transform: translate(-110%, -50%);
        }
      }
      li {
        padding: 3px 0;
        display: flex;
        align-items: center;
        color: $color-gray;
        transition: 0.2s;
        white-space: nowrap;
        &:before {
          margin-#{$rdirection}: 6px;
          content: $cross;
          display: inline-block;
          color: $color-red;
          font-size: 16px;
          line-height: 1px;
          transition: 0.2s;
        }
        &.pass {
          color: $color--success;
          &:before {
            color: $color--success;
            content: $tick;
            text-shadow: 0 0 8px ($color-black, 0.5);
          }
        }
      }
    }
    &__popup {
      column-count: 2;
    }
  }
  .profile__change-password-overlay {
    .profile__reset-password {
      .profile-info {
        &__item {
          input {
            width: 85%;
          }
          .form-cancel {
            float: #{$ldirection};
            width: 40px;
          }
        }
      }
    }
  }
  #confirmation-page {
    .password-field {
      &__info {
        &-checkout {
          @include breakpoint($medium-up) {
            width: 50%;
            #{$ldirection}: 95%;
          }
        }
      }
    }
  }
}
