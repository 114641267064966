#signin {
  fieldset {
    .return-user__divider,
    .new-account__divider {
      display: none;
    }
    .return-user__item {
      .link {
        text-decoration: none;
      }
    }
    .mobile-phone {
      .mobile-number-label {
        text-align: left;
      }
      .mobile-code,
      .mobile-number {
        width: 49%;
        display: inline-block;
        select,
        input[type='text'] {
          width: 100%;
        }
      }
    }
    .form-item {
      &.sister_brand_opt-out {
        text-align: left;
      }
    }
  }
  .new-account,
  .return-user,
  .social_login_section {
    float: left;
  }
  .social-login__divider {
    .inner {
      top: 60%;
    }
  }
  @include breakpoint($medium-up) {
    .error_messages {
      margin-left: 0;
    }
  }
  @include breakpoint($landscape-up) {
    .error_messages {
      max-width: 365px;
    }
  }
  .site-header__utility-right {
    .gnav-util {
      .error_messages {
        margin-left: 0;
      }
    }
  }
}

#colorbox {
  #cboxLoadedContent {
    .address-overlay {
      .address-form {
        .form-field,
        .form-item {
          float: left;
          width: 50%;
          padding-right: 25px;
          select,
          input[type='text'],
          input[type='tel'] {
            width: 100%;
            padding-left: 15px;
          }
          input[type='tel'] {
            padding-right: 10px;
          }
        }
        .title-field {
          float: none;
        }
        .default-shipping {
          width: 100%;
        }
        &__phones {
          float: left;
          width: 100%;
          padding-right: 25px;
        }
      }
      .buttons {
        a.button {
          display: none;
        }
        .address-overlay_submit {
          float: left;
        }
      }
    }
    .address-delete {
      &__header {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      &__link {
        margin-right: 30px;
      }
    }
  }
}

#profile_preferences {
  .profile-page {
    .profile-info {
      &__item {
        .selectBox-dropdown,
        select {
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
      }
    }
    &__container--form {
      .profile-info__fieldset {
        .profile-info__item {
          width: 48%;
          .select {
            width: 100%;
          }
          label {
            display: inline-block;
          }
          .mobile-number-label {
            display: block;
          }
          @include breakpoint($medium-portrait-only) {
            width: 100%;
          }
        }
        .gender {
          width: 48%;
          float: left;
          label {
            margin-right: 20px;
            @include breakpoint($medium-landscape-only) {
              margin-right: 5px;
            }
          }
          @include breakpoint($medium-portrait-only) {
            width: 100%;
            label {
              float: left;
            }
          }
        }
        .mobile-phone,
        .home-phone {
          .mobile-code,
          .mobile-number {
            width: 49%;
            display: inline-block;
            &_field {
              padding: 0 15px;
            }
            select,
            input[type='text'] {
              width: 100%;
              height: 40px;
            }
          }
        }
      }
      .newsletter-info {
        margin-top: 0;
        .newsletter-info__header {
          margin-bottom: 0;
        }
        &__fieldset {
          width: 100%;
          max-width: 100%;
          .sms-promo-wrapper {
            .form-item {
              float: left;
              width: 55%;
              margin-bottom: 5px;
            }
          }
        }
      }
      .account-page__section {
        border: none;
        &.privacy-policy,
        &.opt-out {
          padding-bottom: 25px;
        }
      }
      .opt-out {
        &__item {
          padding-top: 25px;
        }
      }
    }
    select {
      height: 40px;
    }
  }
}

#index {
  .account-overview__section {
    @include breakpoint($medium-up) {
      &.account-overview__profile {
        width: 50%;
      }
    }
    .account-overview__container {
      .section-content {
        .overview-order-info {
          margin-bottom: 20px;
          .account-profile__last-order--number {
            a.link {
              pointer-events: auto;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#orders,
#order_detail {
  .orders-page__content,
  .order-details-page__content {
    .order-products {
      .cart-item__products {
        width: 40%;
      }
      .cart-item__price {
        width: 14%;
      }
      .cart-item__qty {
        width: 20%;
      }
      .cart-item__total {
        width: 26%;
      }
      .product-info__spp-status {
        display: inline-block;
        width: 100%;
        margin-left: 35px;
        color: $color-error;
      }
    }
    .order-details {
      &__item {
        .shipping_header,
        .payment_header {
          margin-top: 30px;
        }
      }
    }
    .orders-list {
      &__table--order-number {
        a.link {
          pointer-events: auto;
          cursor: pointer;
        }
      }
      &__item {
        .cart-item__color span,
        .product_contains span {
          width: auto;
          font-weight: normal;
          text-transform: none;
          word-break: break-word;
        }
      }
    }
  }
}

#favorites,
#purchases {
  .account-section {
    .favorites__list,
    .past-purchases {
      .product-brief {
        padding-bottom: 90px;
        &:hover {
          padding-bottom: 90px;
        }
      }
    }
  }
}

#foundation_finder {
  .account-page {
    .ff-results {
      &-buttons {
        text-align: center;
        width: 47%;
        margin: 0 auto;
      }
      .ff-button--back {
        display: none;
      }
      .ff-button--share-results {
        width: 100%;
      }
    }
  }
}

.en_HK {
  .account-section {
    .account-just-for-you {
      .product-brief {
        &__description {
          .product__subline {
            a {
              display: none;
            }
          }
        }
      }
    }
  }
}

.sign-in-page {
  .mobile-phone {
    padding-top: 10px;
    text-align: $ldirection;
    .mobile-code {
      display: inline-block;
      select {
        height: 47px;
        line-height: 47px;
      }
    }
    .mobile-number {
      display: inline-block;
      width: 75%;
    }
    input[type='tel'] {
      height: 47px;
    }
  }
}
