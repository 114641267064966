[lang='en-HK'] {
  body {
    .cart-item,
    .sticky-add-to-bag,
    .product-full,
    .product {
      &__subline {
        display: none;
      }
    }
    @include breakpoint($small-down) {
      .esearch-nav {
        .product-brief {
          &__subline {
            display: none;
          }
        }
      }
    }
    @include breakpoint($landscape-up) {
      .product-breadcrumb {
        .product-breadcrumb__link {
          &--subline {
            display: none;
          }
        }
      }
    }
  }
}

body {
  #colorbox {
    #cboxContent {
      .waitlist-form {
        height: 400px;
        @include breakpoint($medium-up) {
          height: 250px;
        }
        &__form {
          .error_messages {
            color: $color-error;
          }
          @include breakpoint($small-down) {
            input[type='submit'] {
              margin-top: 10px;
            }
          }
        }
        @include breakpoint($landscape-up) {
          &__header {
            font-size: 53px;
          }
        }
      }
      .ff-results-share {
        &__twitter_icon {
          display: none;
        }
        &__facebook_icon {
          margin-left: 20px;
        }
      }
    }
  }
  select::-ms-expand {
    display: none;
  }
  .site-content {
    .product-grid {
      .product-brief__info {
        .product-price {
          span.product-price__prefix:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}

.customer-service-shipping-page {
  .basic-responsive {
    table,
    th,
    tr,
    td {
      border: 1px solid $color-black;
    }
    table {
      width: 100%;
      td {
        padding: 1em;
      }
    }
  }
}

.signup-tout-wrapper {
  .signup-tout-content {
    .headline {
      padding-top: 10px;
      @include breakpoint($small-down) {
        word-break: break-word;
      }
    }
  }
}

.section-stores {
  .store-locator {
    &__form-column {
      .store,
      .spa {
        display: none;
      }
    }
  }
}

[lang='zh-hant-e-HK'],
.zh_HK {
  .product-brief {
    &__description {
      .product-subline {
        a {
          font-style: normal;
        }
      }
    }
    &__info {
      .product__description-short {
        font-style: normal;
      }
    }
    .product-item__details {
      .product__description-short {
        font-style: normal;
      }
    }
  }
  .product-full {
    &__container {
      .product-full__content {
        .product-full__subline,
        .product-full__desc {
          font-style: normal;
        }
      }
      @include breakpoint($landscape-up) {
        .product-breadcrumb {
          .product-breadcrumb__link {
            &--product {
              display: none;
            }
            &--subline {
              display: inline-block;
            }
          }
        }
      }
    }
  }
  .product-grid {
    .product-quickshop {
      &__description {
        .product-subline {
          a {
            font-style: normal;
          }
        }
      }
    }
  }
  #colorbox {
    #cboxContent {
      .product-quickshop {
        &__content {
          .product-subline {
            a {
              font-style: normal;
            }
          }
        }
      }
    }
  }
  @include breakpoint($landscape-up) {
    .footer {
      .site-footer__bottom {
        &-links {
          padding-right: 19%;
        }
      }
    }
  }
}

.esearch-nav {
  .typeahead-wrapper {
    .see-results {
      &--upper {
        .search-results {
          display: inline-block;
          margin: 0 5px;
          @include breakpoint($landscape-up) {
            padding: 0;
          }
        }
      }
    }
  }
}

@include breakpoint($medium-up) {
  .olapic-block__body {
    max-width: 850px;
  }
}

.invis-form__select--radius {
  display: none;
}

#ot-sdk-btn {
  &.ot-sdk-show-settings {
    font-size: 15px !important;
    color: $color-dark-gray !important;
  }
}

.order-details {
  &__item-info {
    .gift_message {
      text-transform: none;
    }
  }
}
